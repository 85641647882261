<template>
  <div style="position: relative;">
    <div style="position:absolute;top:10px;z-index: 99;right: 13px;">
      <qzf-video vid="63dd60f588c95f0fcbc27298c7151af8_6"></qzf-video>
    </div>
    <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
      <div style="float:left;margin-bottom:10px">
        <el-button icon="RefreshRight" size="small" style="margin-right: 5px;" @click="getList()"></el-button>
        <el-select size="small" v-model="listQuery.lableStatus" placeholder="请选择标签设置状态"
          style="width:150px;margin-left: 0px;" @change="getList" v-if="activeName == '1'">
          <el-option label="全部" value=""></el-option>
          <el-option label="已设置" value="1"></el-option>
          <el-option label="未设置" value="2"></el-option>
        </el-select>
        <el-input placeholder="请输入标签" v-model="listQuery.labelLogo" style="width: 130px;" size="small"
          @keyup.enter="getList" clearable v-if="activeName == '1'" />
        <el-input placeholder="请输入企业名称/税号" v-model="listQuery.name" style="width: 180px;margin-left: 5px;" size="small"
          @keyup.enter="getList" v-if="activeName == '1'" clearable />
        <el-input placeholder="请输入企业名称/编号" v-model="listQuery.name" style="width: 160px;" class="filter-item" size="small"
          @keyup.enter="getList" clearable v-if="activeName == '2'" />
        <el-button type="primary" size="small" @click="getList" icon="Search" style="margin-right:10px">
          搜索
        </el-button>
        <search @success="getList" @cancel="cancel">
          <el-form style="margin:11px 0px 0px 6px;width:500px" class="styleForm" label-width="105px">
            <el-form-item label="税局：">
              <selectcity v-model:citys="listQuery.districtCode" style="width:200px;margin: 0;"></selectcity>
            </el-form-item>
            <el-form-item label="历史匹配状态：" v-if="activeName == '1'">
              <el-checkbox-group size="small" v-model="listQuery.matchTaskStatus">
                <el-checkbox-button v-for="item in matchOptions" :key="item.value" :label="item.value">{{ item.label
                }}</el-checkbox-button>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="迁移公司状态：" v-if="activeName == '1'">
              <el-select size="small" v-model="listQuery.newId" placeholder="请选择" style="width:200px;margin: 0;">
                <el-option key="1" label="未迁移" :value="2"></el-option>
                <el-option key="2" label="已迁移" :value="1"></el-option>
                <el-option key="2" label="已存在，不可迁移" :value="3"></el-option>
                <el-option key="2" label="全部" :value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="税务采集状态：" v-if="activeName == '1'">
              <el-select size="small" v-model="listQuery.setUpStatusCj" placeholder="请选择" filterable
                style="width:200px;margin: 0;">
                <el-option label="全部" :value="8"></el-option>
                <el-option label="采集成功" :value="1"></el-option>
                <el-option label="未采集" :value="6"></el-option>
                <el-option label="跳过采集" :value="2"></el-option>
                <el-option label="部分采集失败" :value="5"></el-option>
                <el-option label="采集中" :value="3"></el-option>
                <el-option label="采集失败" :value="4"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="账套迁移状态：" v-if="activeName == '1'">
              <el-checkbox-group size="small" v-model="listQuery.moveTaskStatus">
                <el-checkbox-button v-for="item in moveOptions" :key="item.value" :label="item.value">{{ item.label
                }}</el-checkbox-button>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="选择人员：" v-if="activeName == '2'">
              <selectuser code="bookkeeping" v-model:userId="listQuery.userId" style="width:200px;margin: 0;">
              </selectuser>
            </el-form-item>
            <el-form-item label="会计准则：" v-if="activeName == '2'">
              <el-select v-model="listQuery.accountSystem" placeholder="请选择会计准则" size="small" clearable style="width:200px;margin: 0;">
                <el-option v-for="item in kjOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="建账状态：" v-if="activeName == '2'">
              <selectJzStatus v-model:jzStatus="listQuery.setUpStatusJz"></selectJzStatus>
            </el-form-item>
            <el-form-item label="采集状态：" v-if="activeName == '2'">
              <selectCjJzStatus v-model:allStatus="listQuery.allStatus"></selectCjJzStatus>
            </el-form-item>
            <el-form-item label="导入状态：" v-if="activeName == '2'">
              <el-checkbox-group size="small" v-model="listQuery.importTaskStatus">
                <el-checkbox-button v-for="item in importOptions" :key="item.value" :label="item.value">{{ item.label
                }}</el-checkbox-button>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="匹配状态：" v-if="activeName == '2'">
              <el-checkbox-group size="small" v-model="listQuery.matchTaskStatus">
                <el-checkbox-button v-for="item in matchOptions" :key="item.value" :label="item.value">{{ item.label
                }}</el-checkbox-button>
              </el-checkbox-group>
            </el-form-item>
          </el-form>
        </search>
      </div>
      <el-tab-pane label="迁移工具" name="1">
        <migrateTool ref="migrateTool" :listQuery="listQuery"></migrateTool>
      </el-tab-pane>
      <el-tab-pane label="历史数据" name="2">
        <contentTableLS ref="contentTableLS" :listQuery="listQuery"></contentTableLS>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import migrateTool from "./components/migrateTool.vue";
import contentTableLS from "./components/contentTableLS.vue";
import selectJzStatus from "@/components/Screening/selectJzStatus";
import selectCjJzStatus from "./components/selectCjJzStatus.vue";
import { currentAccountPeriod } from "@/utils";
import selectcity from "@/components/Screening/selectcity";
import { ACCOUNT_SYSTEM_OPTION } from '@/utils/commonData.js'
export default {
  name: "transfer",
  components: {
    migrateTool,
    contentTableLS,
    selectJzStatus,
    selectCjJzStatus,
    selectcity
  },
  props: {},
  data() {
    return {
      activeName: "1",
      listQuery: {
        page: 1,
        limit: 20,
        setUpStatusCj: 8,
        moveTaskStatus: [],
        matchTaskStatus: [],
        newId: 0,
        name: "",
        taskName: "his-cj-historyCollect",
        period: currentAccountPeriod(),
        taskStatus: [],
        importTaskStatus: [],
        allStatus: [],
      },
      matchOptions: [
        {
          value: 0,
          label: "未匹配",
        },
        {
          value: 1,
          label: "排队中",
        },
        {
          value: 2,
          label: "匹配中",
        },
        {
          value: 3,
          label: "匹配完成",
        },
        {
          value: 4,
          label: "匹配失败",
        },
      ],
      moveOptions: [
        {
          value: 0,
          label: "未迁移",
        },
        {
          value: 1,
          label: "迁移中",
        },
        {
          value: 3,
          label: "已迁移",
        },
        {
          value: 4,
          label: "迁移失败",
        },
        {
          value: 5,
          label: "已迁移(提示)",
        },
      ],
      importOptions: [
        {
          value: 0,
          label: "未导入",
        },
        {
          value: 1,
          label: "排队中",
        },
        {
          value: 2,
          label: "导入中",
        },
        {
          value: 3,
          label: "导入完成",
        },
        {
          value: 4,
          label: "导入失败",
        },
      ],
      kjOptions:ACCOUNT_SYSTEM_OPTION
    };
  },
  mounted() {
    this.listQuery.limit = localStorage.getItem('transfer') ? localStorage.getItem('transfer') * 1 : 20
    this.getList();
  },
  methods: {
    // 重置
    cancel(){
      let originLimit = this.listQuery.limit
      this.listQuery={
        page: 1,
        limit: originLimit,
        setUpStatusCj: 8,
        moveTaskStatus: [],
        matchTaskStatus: [],
        newId: 0,
        name: "",
        accountSystem:'',
        setUpStatusJz:null,
        districtCode:null,
        taskName: "his-cj-historyCollect",
        period: currentAccountPeriod(),
        taskStatus: [],
        importTaskStatus: [],
        allStatus: [],
       }
       this.$nextTick(()=>{
        this.getList();
       })
     },
    getList() {
      if (this.activeName == "1") {
        this.$refs.migrateTool.getList();
      } else if (this.activeName == "2") {
        this.$refs.contentTableLS.getList();
      }
    },
    handleClick(tab) {
      this.activeName = tab.props.name;
      this.getList();
    },
  },
};
</script>

<style scoped lang="less"></style>
