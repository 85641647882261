<template>
  <el-checkbox-group size="small" @change="changeValue" v-model="jzStatus">
    <el-checkbox-button
      v-for="item in options"
      :key="item.value"
      :label="item.value"
      >{{ item.label }}</el-checkbox-button
    >
  </el-checkbox-group>
</template>

<script>
export default {
  name: "selectJzStatus",
  props: {
    jzStatus: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      options: [
        {
          value: 1,
          label: "已初始化",
        },
        {
          value: 6,
          label: "未初始化",
        },
        {
          value: 2,
          label: "无需初始化",
        },
      ],
      value: "",
    };
  },
  methods: {
    changeValue() {
      this.$emit("update:jzStatus", this.jzStatus);
      this.$emit("success", this.jzStatus);
    },
  },
};
</script>

<style></style>
