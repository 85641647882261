<template>
  <div class="main">
    <div class="top_select">
      <div> </div>
      <div>
        <el-button
          size="small"
          type="primary"
          @click="batchMoveCom"
          :disabled="selects.length == 0 || !$buttonStatus('ztqy_qygs')"
          plain
          icon="School"
          :loading="comLoading"
        >
          批量迁移公司
        </el-button>
        <el-button
          size="small"
          type="success"
          @click="batchMoveZt"
          :disabled="selects.length == 0 || !$buttonStatus('ztqy_qyzt')"
          icon="ScaleToOriginal"
          :loading="ztLoading"
        >
          批量迁移账套
        </el-button>
        <el-button
          size="small"
          type="success"
          @click="daochu"
          icon="Folder"
          :loading="dcLoading"
          plain
        >
          导出
        </el-button>
      </div>
    </div>
    <el-table
      stripe
      size="small"
      border
      :height="contentStyleObj"
      :data="list"
      v-loading="loading"
      @select="handleSelectionChange"
      element-loading-text="正在请求账套迁移工具数据，请稍等..."
      :element-loading-svg="svg"
      ref="tableScroll"
      id="tableLazyLoad"
      @select-all="handleSelectionChangeAll"
    >
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column align="center" prop="id" type="selection" width="55" />
      <el-table-column align="center" type="index" label="编号" fixed width="50" />
      <el-table-column
        align="center"
        prop="labelLogo"
        label="软件标签"
        width="100"
        fixed
        show-overflow-tooltip
      />
      <el-table-column
        align="left"
        prop="name"
        label="公司名称"
        min-width="260"
        class="gsmc"
        fixed
      >
        <template #default="scope">
          <div style="display: flex;align-items: center;">
            <div
              style="width: 95%; white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"
            >
              <span v-if="scope.row.type == '1'" class="xgmStyle">小</span>
              <span v-if="scope.row.type == '2'" class="ybStyle">般</span>
              <span>{{ scope.row.name }}</span>
            </div>
            <el-tooltip content="复制" placement="top" effect="dark">
              <i
                class="iconfont icon-fuzhi1"
                style="color:var(--themeColor,#17a2b8);cursor: pointer;"
                @click="$copyComName(scope.row.name)"
              >
              </i>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="districtName"
        label="税局"
        width="65"
        align="center"
      >
        <template #default="scope">
          <span>{{ $cityFilter(scope.row.district) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="startAccountPeriod"
        label="历史数据初始账期"
        width="130"
      />
      <el-table-column
        align="center"
        prop="districtName"
        label="历史数据匹配状态"
        width="130"
      >
        <template #default="scope">
          <div class="div_p">
            <div class="item_icon">
              <p v-if="scope.row.matchTaskStatus == '0'">
                <i class="iconfont icon-gantanhao"></i
                >{{ $setUpStatusMatch(scope.row.matchTaskStatus) }}
              </p>
              <p v-if="scope.row.matchTaskStatus == '1'">
                <i class="iconfont icon-info"></i
                >{{ $setUpStatusMatch(scope.row.matchTaskStatus) }}
              </p>
              <p v-if="scope.row.matchTaskStatus == '2'">
                <i class="iconfont icon-info"></i
                >{{ $setUpStatusMatch(scope.row.matchTaskStatus) }}
              </p>
              <p v-if="scope.row.matchTaskStatus == '3'">
                <i class="iconfont icon-duihao"></i>
                <el-tooltip class="item" effect="dark" placement="top-start">
                  <template #content>
                    <div v-html="scope.row.matchErrLog"></div>
                  </template>
                  <span>{{
                    $setUpStatusMatch(scope.row.matchTaskStatus)
                  }}</span>
                </el-tooltip>
              </p>
              <p v-if="scope.row.matchTaskStatus == '4'">
                <i class="iconfont icon-cuowu"></i>
                <el-tooltip class="item" effect="dark" placement="top-start">
                  <template #content>
                    <div v-html="scope.row.matchErrLog"></div>
                  </template>
                  <span>{{
                    $setUpStatusMatch(scope.row.matchTaskStatus)
                  }}</span>
                </el-tooltip>
              </p>
              <p v-if="scope.row.matchTaskStatus == '5'">
                <i class="iconfont icon-cuowu"></i
                >{{ $setUpStatusMatch(scope.row.matchTaskStatus) }}
              </p>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="name"
        label="迁移公司状态"
        width="140"
      >
        <template #default="scope">
          <div class="div_p">
            <div class="item_icon">
              <p v-if="scope.row.newId == 0">
                <i class="iconfont icon-gantanhao"></i>未迁移
              </p>
              <p v-else>
                <span v-if="scope.row.mpid == 1">
                  <i class="iconfont icon-duihao"></i>已迁移</span
                >
                <span v-else>
                  <i class="iconfont icon-cuowu"></i>已存在,不可迁移</span
                >
              </p>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="税务采集状态" width="180">
        <template #default="scope">
          <div
            class="item_icon"
            v-if="scope.row.setUpStatusCj == 6 || scope.row.setUpStatusCj == 0"
          >
            <i class="iconfont icon-gantanhao"></i>
            <p>{{ setUpStatus3(scope.row.setUpStatusCj) }}</p>
          </div>
          <div class="item_icon" v-else-if="scope.row.setUpStatusCj == 1">
            <i class="iconfont icon-duihao"></i>
            <p>{{ setUpStatus3(scope.row.setUpStatusCj) }}</p>
          </div>
          <div class="item_icon" v-else-if="scope.row.setUpStatusCj == 2">
            <i class="iconfont icon-info"></i>
            <p>{{ setUpStatus3(scope.row.setUpStatusCj) }}</p>
          </div>
          <div class="item_icon" v-else-if="scope.row.setUpStatusCj == 3 || scope.row.setUpStatusCj == 9">
            <i class="iconfont icon-info"></i>
            <p>{{ setUpStatus3(scope.row.setUpStatusCj) }}</p>
          </div>
          <div class="item_icon" v-else-if="scope.row.setUpStatusCj == 4">
            <i class="iconfont icon-cuowu"></i>
            <p style="color:red">
              {{ setUpStatus3(scope.row.setUpStatusCj) }}
            </p>
          </div>
          <div class="item_icon" v-else-if="scope.row.setUpStatusCj == 5">
            <i class="iconfont icon-duihao" style="color:#e6a23c"></i>
            <p style="color:#e6a23c">
              {{ setUpStatus3(scope.row.setUpStatusCj) }}
            </p>
          </div>
          <div class="item_icon" v-else-if="scope.row.setUpStatusCj == 7">
            <i class="iconfont icon-cuowu"></i>
            <p style="color:red">
              {{ setUpStatus3(scope.row.setUpStatusCj) }}
            </p>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="name"
        label="迁移账套状态"
        width="120"
      >
        <template #default="scope">
          <div class="div_p">
            <div class="item_icon">
              <p v-if="scope.row.moveTaskStatus == 0">
                <i class="iconfont icon-gantanhao"></i>未迁移
              </p>

              <p
                v-else-if="
                  scope.row.moveTaskStatus == 1 || scope.row.moveTaskStatus == 2
                "
              >
                <i class="iconfont icon-info"></i>迁移中
              </p>
              <p v-else-if="scope.row.moveTaskStatus == 3">
                <i class="iconfont icon-duihao"></i>已迁移
              </p>
              <p v-else-if="scope.row.moveTaskStatus == 4">
                <i class="iconfont icon-cuowu"></i>
                <el-tooltip class="item" effect="dark" placement="top-start">
                  <template #content>
                    <div v-html="scope.row.moveErrLog"></div>
                  </template>
                  <span>迁移失败</span>
                </el-tooltip>
              </p>
              <p v-if="scope.row.moveTaskStatus == 5">
                <i class="iconfont icon-duihao"></i>
                <el-tooltip class="item" effect="dark" placement="top-start">
                  <template #content>
                    <div v-html="scope.row.moveErrLog"></div>
                  </template>
                  已迁移(提示)
                </el-tooltip>
              </p>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" min-width="220">
        <template #default="scope">
          <el-button
            size="small"
            type="primary"
            :disabled="
              scope.row.matchTaskStatus != 3 ||
                (scope.row.newId !== 0 && scope.row.mpid == 1) ||
                !$buttonStatus('ztqy_qygs')
            "
            @click="moveCom(scope.row)"
            plain
            icon="School"
            >迁移公司</el-button
          >
          <el-button
            size="small"
            type="success"
            :disabled="
              scope.row.matchTaskStatus != 3 ||
                (scope.row.newId !== 0 && scope.row.mpid == 0) ||
                scope.row.newId == 0 ||
                (scope.row.setUpStatusCj != 2 &&
                  scope.row.setUpStatusCj != 1) ||
                scope.row.moveTaskStatus == 3 ||
                scope.row.moveTaskStatus == 5 ||
                !$buttonStatus('ztqy_qyzt')
            "
            @click="moveZt(scope.row)"
            icon="ScaleToOriginal"
            >迁移账套</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <qzf-pagination
        v-show="total > 0"
        :total="total"
        v-model:page="listQuery.page"
        v-model:limit="listQuery.limit"
        @pagination="getList"
        type="transfer"
      />
    </div>
  </div>
</template>

<script>
import { canMoveCompanyList, moveCompany, move20Send } from "@/api/init";
import { exportMoveCompany } from "@/api/export";
export default {
  name: "transfer",
  props: {
    listQuery: {},
  },
  data() {
    return {
      list: [],
      total: 0,
      contentStyleObj: {},
      loading: false,
      ids: [],
      selects: [],
      formLabelWidth: "110px",
      svg: `
            <path class="path" d="
              M 30 15
              L 28 17
              M 25.61 25.61
              A 15 15, 0, 0, 1, 15 30
              A 15 15, 0, 1, 1, 27.99 7.5
              L 15 15
            " style="stroke-width: 4px; fill: rgba(0, 0, 0, 0)"/>
          `,
      comLoading:false,
      ztLoading:false,
      totalPage:0,
      pageSize:50,
      tableData:[],
      currentPage:1,
      allChecked:false,
      dcLoading:false
    };
  },
  mounted() {
    // this.getList();
    this.contentStyleObj = this.$getHeight(246);
  },
  methods: {
    getList() {
      this.loading = true;
      canMoveCompanyList(this.listQuery).then((res) => {
        this.loading = false;
        if (res.data.msg == "success") {
          this.tableData = res.data.data.list ? res.data.data.list : [];
          this.total = res.data.data.total;
          this.setScroll()
        }
      });
    },
    setScroll(){
      //先重置状态和数组
      this.allChecked = false
      this.selects = []
      this.ids = []
      //计算滚动页数
      this.totalPage = this.tableData.length/this.pageSize
      if(this.tableData.length % this.pageSize == 0){
        this.totalPage = this.tableData.length/this.pageSize
      }else{
        this.totalPage = parseInt(this.tableData.length/this.pageSize) + 1
      }
      this.list = this.tableData.slice(0,this.currentPage*this.pageSize)
      this.lazyLoading()
    },
    lazyLoading(){
      let lazy = document.getElementById("tableLazyLoad");
      let dom = lazy.querySelector(".el-scrollbar__wrap");
      let that = this
      dom.addEventListener("scroll", function () {
        const scrollDistance = dom.scrollHeight - dom.scrollTop - dom.clientHeight;
        if (scrollDistance <= 5) {
          if (that.currentPage < that.totalPage) {
            that.currentPage++;
            that.list = that.tableData.slice(
              0,
              that.currentPage * that.pageSize
            );
            if(that.allChecked && that.selects.length == that.tableData.length){
              that.$refs.tableScroll.toggleAllSelection()
              that.allChecked = true
            }else if(that.selects.length != 0){
              that.selects.map(v=>{
                that.list.map(item=>{
                  if(item.InitComId == v.InitComId){
                    that.$nextTick(()=>{
                     that.$refs.tableScroll.toggleRowSelection(item,true)
                    })
                  }
                })
              })
            }
          }
        }
      });
    },
    getIds(){
      let ids = [];
      this.selects.map((v) => {
        ids.push(v.InitComId);
      });
      this.ids = ids;
    },
    handleSelectionChange(val) {
      if(this.allChecked && this.list.length != this.tableData.length){
        let currentIndex = 0
        this.list.map((v,i2)=>{
          val.map(e=>{
            if(e.InitComId == v.InitComId){
              currentIndex = i2
            }
          })
        })
        let arr = this.tableData.slice(currentIndex+1,this.tableData.length)
        let newArr = [...val,...arr]
        this.selects = newArr
      }else{
        this.selects = val;
      }
      this.getIds()
    },
    handleSelectionChangeAll(e){
      if(e.length != 0){
        this.selects = this.tableData
        this.getIds()
        this.allChecked = true
      }else{
        this.selects = []
        this.ids = []
        this.allChecked = false
      }
    },
    //迁移公司
    moveCom(row) {
      if (row.newId != 0) {
        if (row.mpid == 0) {
          this.$qzfMessage("系统内已存在" + row.name + "，不可迁移", 1);
          return;
        } else {
          this.$qzfMessage(row.name + "已迁移", 1);
          return;
        }
      }
      this.$confirm("确认迁移该公司", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let param = {
          comIds: [row.InitComId],
        };
        this.moveComSure(param);
      });
    },
    //批量迁移公司
    batchMoveCom() {
      let str = '';
      this.selects.map((v) => {
        if (v.matchTaskStatus != 3) {
          str = v.name + '历史数据未匹配';
          return;
        }
      });
      if (str) {
        this.$qzfMessage(str, 1);
        return;
      }
      let comStr = '';
      this.selects.map((e) => {
        if (e.newId != 0) {
          comStr = e.name + '已迁移完成，请重新选择';
          return;
        }
      });
      if (comStr) {
        this.$qzfMessage(comStr, 1);
        return;
      }
      this.$confirm("确认批量迁移公司？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.comLoading = true
        let param = {
          comIds: this.ids,
        };
        this.moveComSure(param);
      });
    },
    moveComSure(param) {
      moveCompany(param).then((res) => {
        this.comLoading = false
        if (res.data.msg == "success") {
          this.$qzfMessage("迁移成功，请进行税务采集");
          this.getList();
        }
      });
    },
    //迁移账套
    moveZt(row) {
      this.$confirm("确认迁移该公司账套？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let param = {
          comIds: [row.InitComId],
        };
        this.moveZtSure(param);
      });
    },
    //批量迁移账套
    batchMoveZt() {
      let str = '';
      this.selects.map((v) => {
        if (v.matchTaskStatus != 3) {
          str = v.name + '历史数据未匹配';
          return;
        }
      });
      if (str) {
        this.$qzfMessage(str, 1);
        return;
      }
      let comStr = '';
      this.selects.map((e) => {
        if (e.newId == 0) {
          comStr = e.name + '未迁移';
          return;
        }
      });
      if (comStr) {
        this.$qzfMessage(comStr, 1);
        return;
      }
      let moveComStr = '';
      this.selects.map((e) => {
        if (e.newId != 0 && e.mpid == 0) {
          moveComStr = e.name + '已存在，不可迁移';
          return;
        }
      });
      if (moveComStr) {
        this.$qzfMessage(moveComStr, 1);
        return;
      }
      let swStr = '';
      this.selects.map((e) => {
        if (e.setUpStatusCj != 2 && e.setUpStatusCj != 1) {
          swStr = e.name + '未进行税务采集';
          return;
        }
      });
      if (swStr) {
        this.$qzfMessage(swStr, 1);
        return;
      }
      let ztStr = '';
      this.selects.map((e) => {
        if (e.moveTaskStatus == 3 || e.moveTaskStatus == 5) {
          ztStr = e.name + '账套已迁移，请重新选择';
          return;
        }
      });
      if (ztStr) {
        this.$qzfMessage(ztStr, 1);
        return;
      }
      this.$confirm("确认批量迁移公司账套？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.ztLoading = true
        let param = {
          comIds: this.ids,
        };
        this.moveZtSure(param);
      });
    },
    moveZtSure(param) {
      move20Send(param).then((res) => {
        this.ztLoading = false
        if (res.data.msg == "success") {
          this.$qzfMessage("任务已发起");
          this.getList();
        }
      });
    },
    //导出
    daochu(){
      this.dcLoading = true
      let ids = []
      this.selects.map((e) => {
        ids.push(e.InitComId);
      });
      let param = {
        comIds:ids
      }
      param.info = this.listQuery
      exportMoveCompany(param).then(res=>{
        this.dcLoading = false
        if(res.data.msg == 'success'){
          window.open(res.data.data.url)
        }
      })
    },
    setUpStatus3(e) {
      let num = e;
      if (num == 1) {
        return "采集成功";
      } else if (num == 6 || num == 0) {
        return "未采集";
      } else if (num == 2) {
        return "跳过采集";
      } else if (num == 3) {
        return "排队中";
      } else if (num == 4) {
        return "采集失败";
      } else if (num == 5) {
        return "部分采集失败";
      } else if (num == 9) {
        return "采集中";
      } else if (num == 7) {
        return "任务已撤销";
      } else if (num == 10) {
        return "跳过采集";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.top_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.item_icon {
  display: inline-block;
  cursor: pointer;
  i {
    display: inline-block;
    font-size: 13px;
    margin-right: 4px;
  }
  .iconfont icon-cuowu {
    color: #f56c6c;
  }
  .iconfont icon-duihao {
    color: #67c23a;
  }
  .el-icon-circle-check {
    color: #67c23a;
  }
  .iconfont icon-info {
    color: #409eff;
  }
  .iconfont icon-cuowu {
    color: #e6a23c;
  }
  .iconfont icon-cuowu-outline {
    color: #e6a23c;
  }

  p {
    display: inline-block;
    font-size: 13px;
    color: #333;
  }
}
.sbcg {
  padding: 0 !important;
}
.sbyc {
  color: red !important;
  padding: 0 !important;
}
</style>
