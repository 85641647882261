import request from "@/utils/request";

//公司list
export function canMoveCompanyList(data) {
  return request({
    url: "api/v2/init/canMoveCompanyList",
    method: "post",
    data,
  });
}

//迁移公司
export function moveCompany(data) {
  return request({
    url: "api/v2/init/moveCompany",
    method: "post",
    data,
  });
}

//迁移账套
export function move20Send(data) {
  return request({
    url: "api/v2/init/move20Send",
    method: "post",
    data,
  });
}

//获取模板list
export function areaExcelList(data) {
  return request({
    url: "api/v2/init/areaExcelList",
    method: "post",
    data,
  });
}

