<template>

  <!-- login1 -->
  <div class="appLogin" v-if="this.loginTitle">
    <el-container>
      <el-header>
        <img src="../../assets/newlogo.svg" alt="" class="top_logo">
      </el-header>
      <el-main>
        <el-row :gutter="20">
          <el-col :span="14">
            <div class="login_left">
              <div class="left_title">
                <div class="text">Ai智能财税系统</div>
                <div class="icon">V5.0</div>
              </div>
              <p>Ai财务+会计工厂，实现代账公司节流开源</p>
              <img src="../../assets/loginLeft.svg" alt="" class="left_icon">
              <div class="left_bottom">
                <div class="bottom_text">
                  <img src="../../assets/icon1.png" alt="">
                  月初自动清册
                </div>
                <div style="background-color:#B5D4FD;height: 2em;width: 1.5px;">
                </div>
                <div class="bottom_text">
                  <img src="../../assets/icon2.png" alt="">
                  自动票据采集
                </div>
                <div style="background-color:#B5D4FD;height: 2em;width: 1.5px;">
                </div>
                <div class="bottom_text">
                  <img src="../../assets/icon4.png" alt="">
                  Ai自动记账
                </div>
                <div style="background-color:#B4D2F9;height: 2em;width: 2px;">
                </div>
                <div class="bottom_text">
                  <img src="../../assets/icon3.png" alt="">
                  自动报税检查
                </div>
              </div>
            </div>
          </el-col>
          <el-col class="login-right-box" :span="10">
            <div class="login_right">
              <div class="right_title">
                欢迎使用鲸算盘系统
              </div>
              <div class="right_login">
                <el-input v-model="loginForm.username" placeholder="请输入用户名" name="username" type="text"
                  class="tips inputDeep" @keyup.enter.native="onShow('blockPuzzle')">
                  <template #prefix>
                    <el-icon :size="20">
                      <UserFilled />
                    </el-icon>
                  </template>
                </el-input>
                <el-input type="password" v-model="loginForm.password" placeholder="请输入密码" name="password"
                  class="inputDeep" @keyup.enter.native="onShow('blockPuzzle')" show-password>
                  <template #prefix>
                    <el-icon :size="20" v-if="!loginForm.password">
                      <Lock />
                    </el-icon>
                    <el-icon :size="20" v-else>
                      <Unlock />
                    </el-icon>
                  </template>
                </el-input>
              </div>
              <div style="display: flex;justify-content: space-between;font-size: 14px;margin-top: -10px;">
                <div @click="dialogVisible = true">
                  没账号，
                  <span style="color: #468CF9;cursor: pointer;">点击注册？</span>
                </div>
                <div @click="dialogVisibleFindPass = true" style="cursor: pointer;">
                  忘记密码？
                </div>
              </div>
              <div class="right_btn">
                <el-button type="primary" round @click="onShow('blockPuzzle')">登录</el-button>
              </div>
              <div class="bq">
                Copyright©2018-2022 鲸算盘科技版权所有！<br /> 鲁ICP备2022028805号
              </div>
            </div>
          </el-col>
        </el-row>
      </el-main>
    </el-container>
  </div>

  <div class="login-container2" v-else>
    <div class="background_box">
      <div class="right_form">
        <div class="leftImg">
          <img :src="imgUrl" alt="" srcset="" style="width: 100%;height: 100%;">
        </div>
        <div style="float: left;position:relative;margin-left: 7%;margin-top: 3%;">
          <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" auto-complete="on"
            label-position="left">
            <div class="title-container">
              <h3 class="title">欢迎使用
                <span style="display:inline-block;float:right;cursor: pointer;font-size: 15px;margin-top: 5px;"
                  id="copy" @click="copy()">
                  <el-icon>
                    <DocumentCopy />
                  </el-icon>
                  分享</span>
              </h3>
            </div>

            <el-form-item prop="username">
              <span class="svg-container">
                <el-icon>
                  <User />
                </el-icon>
              </span>
              <el-input v-model="loginForm.username" placeholder="用户名" name="username" type="text"
                class="tips inputDeep" auto-complete="on" @keyup.enter.native="onShow('blockPuzzle')" />
            </el-form-item>

            <el-form-item prop="password">
              <span class="svg-container">
                <el-icon>
                  <Lock />
                </el-icon>
              </span>
              <el-input type="password" v-model="loginForm.password" placeholder="密码" name="password" class="inputDeep"
                @keyup.enter.native="onShow('blockPuzzle')" show-password />
            </el-form-item>
            <el-button size="small" :loading="loading" type="primary" class="login_btn"
              @click.native.prevent="onShow('blockPuzzle')">登 录</el-button>
          </el-form>
          <div>
            <el-checkbox v-model="checked">记住密码</el-checkbox>
            <span @click="dialogVisibleFindPass = true"
              style="diaplay:inline-block;position: relative;right: -168px;color: var(--themeColor,#17a2b8);font-size: 14px;">忘记密码?</span>
          </div>
          <div style="font-size: 14px;margin-top: 20px;">没有账号?去<span
              style="color:var(--themeColor,#17a2b8);cursor: pointer;" @click="dialogVisible = true">注册</span></div>
        </div>
      </div>
    </div>
  </div>
  <!-- 注册 -->
  <!-- 鲸算盘的 -->


  <el-dialog :close-on-click-modal="false" title="注册" v-model="dialogVisible" width="450px">
    <el-form ref="form" label-width="80px" size="small">
      <el-form-item label="手机号">
        <el-input v-model="form.userName" style="width: 160px;"></el-input>
        <el-button size="small" v-if="count" type="primary" style="margin-left:10px">{{ count }}</el-button>
        <el-button size="small" v-else type="primary" @click="getYzm('register')"
          style="margin-left:10px">{{ text }}</el-button>
      </el-form-item>
      <el-form-item label="验证码">
        <el-input v-model="form.yzm" style="width: 160px;"></el-input>
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取消</el-button>
        <el-button size="small" type="primary" @click="remind">确定</el-button>
      </span>
    </template>
  </el-dialog>



  <!-- 找回密码 -->
  <el-dialog :close-on-click-modal="false" title="找回密码" v-model="dialogVisibleFindPass" width="450px">
    <el-form ref="form" label-width="80px" size="small">
      <el-form-item label="手机号">
        <el-input v-model="findPassParam.userName" style="width: 160px;"></el-input>

        <el-button size="small" v-if="count" type="primary" style="margin-left:10px">{{ count }}</el-button>
        <el-button size="small" v-else type="primary" @click="getYzm('find')"
          style="margin-left:10px">{{ text }}</el-button>
      </el-form-item>
      <el-form-item label="验证码">
        <el-input v-model="findPassParam.yzm" style="width: 160px;"></el-input>
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogVisibleFindPass = false">取消</el-button>
        <el-button size="small" type="primary" @click="findPassWord">确定</el-button>
      </span>
    </template>
  </el-dialog>

  <Verify mode="pop" :captchaType="captchaType" :loginForm="loginForm" :imgSize="{ width: '400px', height: '200px' }"
    ref="verify"></Verify>
</template>

<script>
import { escape, unescape } from 'lodash'
import { getCaptcha } from "@/api/public"
import Verify from '@/components/verifition/Verify'
import "../../assets/css/animate.min.css";
import "../../assets/css/style.css";
import { findPass, sendSms, registerRemind } from "@/api/login"
export default {
  name: "login",
  components: {
    Verify
  },
  data() {
    return {
      //找回密码
      dialogVisibleFindPass: false,
      findPassParam: {
        userName: '',
        yzm: ''
      },
      count: 0,
      text: "获取验证码",

      passwordType: 'password',
      img: "",
      loginForm: {
        username: '',
        password: '',
        remember: '',
        captchaId: '',
        captchaValue: '',
      },
      form: {
        userName: '',
        yzm: ''
      },
      mes: 'www.jsptax.net',
      checked: true,
      dialogVisible: false,
      captchaType: '',
      imgUrl: 'https://file.listensoft.net/mnt/v2/img/cover1.jpg',
      loginTitle: true,

      // ../../../src/assets/homeLeft.png
    }
  },
  created() {
    // if(window.location.hostname == 'daizhang.sencha.cc'){
    //   this.imgUrl = "https://file.listensoft.net/mnt/v2/img/login2.jpg"
    // }
    // console.log(window.location.hostname);
    if (window.location.hostname == 'daizhang.jsptax.com' || window.location.hostname == 'localhost' || window.location.hostname == 'tax.jsptax.net') {
      this.loginTitle = true
    }
    else {
      this.loginTitle = false
    }
    this.imgUrl = window.localStorage.getItem('loginImg') ? window.localStorage.getItem('loginImg') : 'https://file.listensoft.net/mnt/v2/img/login1.png'
    this.getyzm()
  },
  mounted() {
    this.getCookie()
  },
  methods: {
    getCode() {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000)
      }
    },
    getYzm(type) {
      let tel = ''
      if (type == "find") {
        tel = this.findPassParam.userName
      } else {
        tel = this.form.userName
      }

      if (!(/^1[3456789]\d{9}$/.test(tel))) {
        this.$message({
          type: 'info',
          message: '请输入正确手机号'
        });
        return
      }
      this.getCode()
      sendSms({ "mobile": tel }).then(res => {
        if (res.data.msg != "success") {
          return
        }
        this.$message({
          message: '发送成功',
          type: 'success'
        })
      })
    },
    findPassWord() {
      findPass(this.findPassParam).then(res => {
        if (res.data.msg == "success") {
          this.dialogVisibleFindPass = false
          this.$alertMsg("您的新密码是：" + res.data.data.pass)
        }
      })
    },
    remind() {
      registerRemind(this.form).then(res => {
        if (res.data.msg == "success") {
          this.dialogVisible = false
          this.$alertMsg("您已成功注册，工作人员会尽快和您联系。")
        }
      })
    },
    onShow(type) {
      this.captchaType = type
      this.$refs.verify.show(this.loginForm)
    },
    getyzm() {
      // getCaptcha({}).then(res=>{
      //   this.img = res.data.data.data.data
      //   this.loginForm.captchaId = res.data.data.data.captchaId
      // })
    },
    handleLogin() {
      this.$store.dispatch('user/LoginByUsername', this.loginForm).then(() => {
        // this.$router.push({ path: this.redirect || '/' })
        if (this.checked === true) {
          this.setCookie(this.loginForm.username, this.loginForm.password, true, 7)
        } else {
          this.clearCookie()
        }
        let time = new Date().getTime();
        localStorage.setItem("time", time);
        this.$router.push({ path: this.redirect || '/' })
      })
        .catch((err) => {
          this.getyzm()
          this.$message.error(err)
        })
    },
    setCookie(c_name, c_pwd, c_state, exdays) {
      // //console.log(c_name,c_pwd,c_state);
      const exdate = new Date()
      var c_name_user = window.btoa(unescape(encodeURIComponent(c_name)))
      var c_pwd_user = window.btoa(unescape(encodeURIComponent(c_pwd)))
      exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays) // 保存的天数
      window.document.cookie = 'username' + '=' + c_name_user + ';path=/;expires=' + exdate.toGMTString()
      window.document.cookie = 'password' + '=' + c_pwd_user + ';path=/;expires=' + exdate.toGMTString()
      window.document.cookie = 'state' + '=' + c_state + ';path=/;expires=' + exdate.toGMTString()
    },
    getCookie() {
      if (document.cookie.length > 0) {
        const arr = document.cookie.split('; ')
        for (let i = 0; i < arr.length; i++) {
          const arr2 = arr[i].split('=')
          if (arr2[0] === 'username') {
            this.loginForm.username = decodeURIComponent(escape(window.atob(arr2[1])))
          } else if (arr2[0] === 'password') {
            this.loginForm.password = decodeURIComponent(escape(window.atob(arr2[1])))
          } else if (arr2[0] === 'state') {
            this.checked = Boolean(arr2[1])
          }
        }
      }
    },
    // 清除cookie
    clearCookie: function () {
      this.setCookie('', '', false, -1)
    },
    copy() {
      //创建input标签
      var input = document.createElement('input')
      //将input的值设置为需要复制的内容
      // if(window.location.hostname == 'daizhang.sencha.cc'){
      //   this.mes = "https://daizhang.sencha.cc"
      // }
      this.mes = window.location.hostname
      input.value = this.mes;
      //添加input标签
      document.body.appendChild(input)
      //选中input标签
      input.select()
      //执行复制
      document.execCommand('copy')
      //成功提示信息
      this.$message.success('复制成功！')
      //移除input标签
      document.body.removeChild(input)
    },
    register() {
      this.dialogVisible = false
    }
  },
  setup() {
    return {

    }
  },
}
</script>



<style rel="stylesheet/scss" lang="scss">
//  @import "../../assets/css/animate.min.css'";
//  @import "../../assets/css/style.css";
@function calvw($num) {
  @return ($num/1920)*100vw;
}
$bg: #eee;
$light_gray: #666;
$cursor: #999;
@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
  .login-container .el-input input {
    color: $cursor;

    &::first-line {
      color: $light_gray;
    }
  }
}

.videoStyle {
  position: absolute;
  top: 50%;
  left: 46.6%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  -webkit-transition: 1s opacity;
  transition: 1s opacity;
}

.el-container.is-vertical {
  flex-direction: column;
  height: 100%;
}

.appLogin {
  background-image: url('../../assets/loginbg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: fixed;
  height: 100%;
  width: 100%;
  height: 100vh;
  /* 使容器高度等于视口高度 */
  font-family: 'Microsoft';

  .login_left {
    width: 100%;
    height: 100%;
    padding:calvw(52) 0 0 calvw(147);
    .left_icon {
      position: relative;
      top: 8%;
      left: 12%;
      width: 78%;
      height: 70%;
    }
  }

  p {
    font-family: 'Microsoft', sans-serif;
    font-size: calvw(18);
    font-weight: 400;
    line-height: calvw(24);
    color: rgba(51, 51, 51, 1);
  }

  .left_title {
    height: calvw(76);
    display: flex;
    align-items: center;

    .text {
      font-size: calvw(36);
      font-weight: bold;
      letter-spacing: 1px;
    }

    .icon {
      width: calvw(66);
      height:calvw(33);
      background-color: #3772EF;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      font-weight: bold;
      border-radius: 20px 20px 20px 0px;
      margin-left: calvw(7);
      font-size: calvw(20);
      font-weight: 700;
      letter-spacing: 2px;
      line-height:calvw(27);
      color: rgba(255, 255, 255, 1);
    }
  }
  .top_logo{
    padding: calvw(28);
  }
  .left_bottom {
    width: 90%;
    margin: 0 auto;
    height: calvw(76);
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 0.625em;
    // box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 25px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 100%);
    margin-top: calvw(10);
    position: relative;
    line-height: calvw(76);
    margin-left: calvw(80);
    padding: calvw(10) calvw(15);
    display: flex;
    align-items: center;
    justify-content: space-around;

    .bottom_text {
      display: flex;
      align-items: center;
      color: #000000;
      font-size: calvw(16);
      font-weight: 400;
      letter-spacing: calvw(1);
      line-height: calvw(21);
      color: rgba(56, 56, 56, 0.9);
    }
  }

  .login_right {
    width: calvw(500);
    height: calvw(605);
    background-color: #ffffff;
    // margin: 12% 0 0 7%;
    margin: calvw(73) 0 0 calvw(43);
    border-radius: 0.5em;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    padding: calvw(84) calvw(32);
  }

  .right_title {
    font-size: calvw(24);
    font-weight: 700;
    line-height: calvw(36);
    color: rgba(51, 51, 51, 1);
    text-align: center;
    letter-spacing: 1.5px;
    height: calvw(36);
  }

  .right_login {
    height: calvw(216);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .login_right .el-input__wrapper {
    border-radius: 0 !important;
    height: calvw(53);
    border-radius: calvw(10) !important;
    background-color: #EFF1F4;
  }

  .login_right .el-input {
    --el-input-border-color: #EFF1F4 !important;
  }

  .login_right .tips {
    margin-bottom: calvw(35);
  }

  .login_right .right_btn {
    text-align: center;
    padding: calvw(32) 0;
    cursor: pointer;
  }

  .login_right .el-button.is-round {
    padding: calvw(25) 0;
  }

  .login_right .el-button--primary {
    background: #478CF9 !important;
    color: #fff;
    border-color: #478CF9 !important;
  }

  .login_right .el-button {
    width: 100%;
    font-size: calvw(16) !important;
    letter-spacing: calvw(2);
    border-radius: calvw(10) !important;
  }

  .login_right .inputDeep {
    border-radius:calvw(10) !important;
  }
}
.bq{
  font-size: calvw(14);
  text-align: center;
}
@media only screen and (max-width: 768px) {
  .appLogin {
    .el-row {
      flex-direction: column;
    }
    .top_logo{
      padding: 1.5%;
    }
    .login_left {
      display: none
    }

    .login-right-box {
      width: 320px !important;
      max-width: unset;
      margin: 0 auto;
      padding: 0 !important;
    }

    .login_right {
      width: 320px;
      height: 500px;
      border-radius:8px;
      padding: 54px 32px;
      margin: 0;
      margin-top: 3.8020833333vw;
    }

    .right_title{
      font-size: 24px;
      line-height: 36px;
      height: 36px;
    }
    .right_login{
      height: 216px;
    }
    .login_right .el-input__wrapper{
      height: 53px;
      border-radius: 10px;
    }
    .login_right .tips {
      margin-bottom: 35px;
    }
    .login_right .el-button.is-round {
      padding: 20px 0;
    }
    .login_right .right_btn {
      padding: 32px 0;
    }
    .login_right .el-button{
      font-size: 16px !important;
      letter-spacing: 2px !important;
      border-radius: 10px !important;
    }
    .login_right .inputDeep {
      border-radius:10px;
    }
    .bq{
      font-size: 13px;
    }
  }
}



.login-container2 {
  width: 100%;
  height: 100%;

  .background_box {
    position: relative;
    min-height: 100%;
    width: 100%;
    height: 100%;
    background-color: rgb(250, 250, 250);
    background-size: 100%;
    overflow: hidden;
    position: relative;
    z-index: 2;
    padding-bottom: 50px;

    .leftImg {
      float: left;
      height: 100%;
      width: 45%;
      border-right: 1px solid lightgray;
    }

    .right_form {
      background-color: #fff;
      position: absolute;
      border-radius: 3px;
      width: 800px;
      z-index: 20;
      height: 520px;
      max-width: 100%;
      margin: auto;
      top: 0;
      bottom: 100px;
      left: 0;
      right: 5px;
      overflow: hidden;
      box-shadow: 0 2px 10px #ddd;

      .login_btn {
        width: 100%;
        margin-bottom: 20px;
        border-radius: 3px !important;
        line-height: 32px !important;
        height: 44px !important;
        font-size: 21px;
      }
    }
  }

  .el-input {
    display: inline-block;
    height: 36px;
    width: 82%;

    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 0 10px;
      color: #333;
      height: 33px;
      line-height: 36px;
      padding: 0 !important;
      caret-color: $cursor;

      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px $bg inset !important;
        -webkit-text-fill-color: $cursor !important;
      }
    }
  }

  .el-form-item {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: #eee;
    border-radius: 3px;
    color: #454545;
    display: flex;
    flex-direction: row;
    margin-bottom: 30px !important;
  }
}
</style>

<style rel="stylesheet/scss" lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;


.login-container2 {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: $bg;

  .login-form {
    width: 130%;
    margin: 0 auto;
    margin-top: 56px;

  }

  .tips {
    font-size: 14px;
    color: #666;

    // margin-bottom: 10px;
    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 0 5px 0 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    // display: inline-block;
    font-size: 17px;
  }

  .title-container {
    position: relative;

    .title {
      font-size: 20px;
      margin: 0px auto 40px auto;
      text-align: left;
    }

    .title2 {
      font-size: 26px;
      color: var(--themeColor, #17a2b8);
      margin: 0px auto 40px auto;
      text-align: left;
      font-weight: bold;
    }

    .set-language {
      color: #fff;
      position: absolute;
      top: 5px;
      right: 0px;
    }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }

  .thirdparty-button {
    position: absolute;
    right: 35px;
    bottom: 28px;
  }

  .inputDeep {
    :deep(.el-input__wrapper) {
      box-shadow: none;
      cursor: default;
      background-color: #eeeeee;

      .el-input__inner {
        cursor: default !important;
      }
    }
  }
}
</style>